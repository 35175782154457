
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditEmployeeSupplierModal from "@/components/modals/referencials/employees-supplier/EditEmployeeSupplierModal.vue";
import SearchEmployeeSupplierModal from "@/components/modals/referencials/employees-supplier/SearchEmployeeSupplierModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-employees-supplier",
    props: {
        widgetClasses: String,
    },
    components: {
        EditEmployeeSupplierModal,
        SearchEmployeeSupplierModal,
    },
    setup() {
        const { t, te } = useI18n();
        let loading = ref(false);
        let activeEmployeeSupplier = ref(null);
        const employeesSupplier = ref([]);
        const queryParamsDefault = {
            supplier_id          : 0,
            code                 : "",
            last_name            : "",
            first_name           : "",
            gender               : "",
            civil_status         : "",
            id_number            : "",
            passport_number      : "",
            email                : "",
            phone                : "",
            address              : "",
            position_id          : 0,
            nationality          : "",
            // analytic_account_code: "",
            is_user              : 0,
            is_driver            : 0,
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            supplier_id          : translate("employeeSupplierSupplierIdLabel"),
            code                 : translate("employeeSupplierCodeLabel"),
            last_name            : translate("employeeSupplierLastNameLabel"),
            first_name           : translate("employeeSupplierFirstNameLabel"),
            gender               : translate("employeeSupplierGenderLabel"),
            civil_status         : translate("employeeSupplierCivilStatusLabel"),
            id_number            : translate("employeeSupplierIdNumberLabel"),
            passport_number      : translate("employeeSupplierPassportNumberLabel"),
            email                : translate("employeeSupplierEmailLabel"),
            phone                : translate("employeeSupplierPhoneLabel"),
            address              : translate("employeeSupplierAddressLabel"),
            position_id          : translate("employeeSupplierPositionIdLabel"),
            nationality          : translate("employeeSupplierNationalityLabel"),
            // analytic_account_code: "Compte Analytique",
            is_user              : translate("employeeSupplierIsUserLabel"),
            is_driver            : translate("employeeSupplierIsDriverLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getEmployeesSupplier();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getEmployeesSupplier();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getEmployeesSupplier();
        };

        const create = () => {
            activeEmployeeSupplier.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_employee_supplier'));
            modal.show();
        };

        const edit = (supplier) => {
            activeEmployeeSupplier.value = supplier;
            const modal = new Modal(document.getElementById('kt_modal_edit_employee_supplier'));
            modal.show();
        };

        const confirmRemove = (supplier_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("employeeSupplierConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(supplier_id);
                }
            })
        }

        const remove = (employee_id: number) => {

            axios.delete(`${ApiRoutes.employeesSupplier.delete}/${employee_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });
                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];
                if (name == 'structure_type') queryParams.value['structure_code'] = queryParamsDefault['structure_code'];

                refresh();
            }
        };

        const getEmployeesSupplier = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.employeesSupplier.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    employeesSupplier.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchEmployeesSupplier = (params) => {
            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getEmployeesSupplier();

            setCurrentPageBreadcrumbs(translate("employeeSupplierManagementHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("employeesManagementHeading")
            ]);
        });

        return {
            translate,
            employeesSupplier,
            queryParams,
            queryParamsDefault,
            queryParamsLabels,
            removeQueryParam,
            searchEmployeesSupplier,
            pagination,
            activeEmployeeSupplier,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
