
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "edit-employee-modal",
    emit: ["refreshData"],
    props: {
        employee: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editEmployeeSupplierModalRef = ref<null | HTMLElement>(null);

        let isUpdate = false as boolean;
        let errors: any = ref();
        let suppliers = ref([]);
        let positions = ref([]);
        let nationalities = ref([]);

        const employeeSupplierDefault = {
            id                   : null,
            supplier_id          : 0,
            code                 : "",
            last_name            : "",
            first_name           : "",
            gender               : "",
            civil_status         : "",
            id_number            : "",
            passport_number      : "",
            email                : "",
            phone                : "",
            address              : "",
            position_id          : 0,
            nationality          : "",
            // analytic_account_code: "",
            is_user              : false,
            is_driver            : false,
        };
        const employeeSupplierData = ref<any>({});
        Object.assign(employeeSupplierData.value, employeeSupplierDefault);

        const validationSchema = Yup.object().shape({
            // supplier_id: Yup.number().required().label(translate("employeeSupplierSupplierIdLabel")),
            // code: Yup.string().required().label(translate("employeeSupplierCodeLabel")),
            // last_name: Yup.string().required().label(translate("employeeSupplierLastNameLabel")),
            // first_name: Yup.string().required().label(translate("employeeSupplierFirstNameLabel")),
            // gender: Yup.string().required().label(translate("employeeSupplierGenderLabel")),
            // civil_status: Yup.string().required().label(translate("employeeSupplierCivilStatusLabel")),
            // id_number: Yup.string().required().label(translate("employeeSupplierIdNumberLabel")),
            // passport_number: Yup.string().required().label(translate("employeeSupplierPassportNumberLabel")),
            // email: Yup.string().email().required().label(translate("employeeSupplierEmailLabel")),
            // phone: Yup.string().required().label(translate("employeeSupplierPhoneLabel")),
            // address: Yup.string().required().label(translate("employeeSupplierAddressLabel")),
            // position_id: Yup.number().required().label(translate("employeeSupplierPositionIdLabel")),
            // nationality: Yup.string().required().label(translate("employeeSupplierNationalityLabel")),
            // // analytic_account_code: Yup.string().nullable().label("Compte Analytique"),
            // is_driver: Yup.boolean().label(translate("employeeSupplierIsDriverLabel")),
            // is_user: Yup.boolean().label(translate("employeeSupplierIsUserLabel")),
        });

        const submit = (values, actions) => {

            errors.value = null;

            disableSubmitButton(submitButtonRef);

            if (isUpdate && props.employee.id) employeeSupplierData.value.id = props.employee.id;

            axios.post(ApiRoutes.employeesSupplier.updateOrCreate, employeeSupplierData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editEmployeeSupplierModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        const getPositions = async () => {
            const response = await axios.get(ApiRoutes.employees.positions);

            positions.value = response.data;
        };

        const getNationalities = async () => {
            const response = await axios.get(ApiRoutes.countries.nationalities);

            nationalities.value = response.data;
        };

        const getSuppliers = async () => {
            const response = await axios.get(ApiRoutes.suppliers.list);

            suppliers.value = response.data;
        };

        onMounted(() => {
            getSuppliers();
            getPositions();
            getNationalities();

            const modal = document.getElementById('kt_modal_edit_employee_supplier');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(employeeSupplierData.value, employeeSupplierDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;
                errors.value = null;

                if (props.employee) {
                    isUpdate = true;

                    // Fill Intra fields
                    Object.keys(props.employee).forEach((key) => {
                        if (employeeSupplierDefault.hasOwnProperty(key)) {
                            if (typeof employeeSupplierDefault[key] == 'boolean') employeeSupplierData.value[key] = Boolean(props.employee[key]);
                            else employeeSupplierData.value[key] = props.employee[key];
                        }
                    });
                }
            });
        });

        return {
            translate,

            submitButtonRef,
            editEmployeeSupplierModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            suppliers,
            positions,
            nationalities,
            employeeSupplierData,

            submit,
        };
    },
});
